// eslint-disable-next-line no-redeclare,no-unused-vars
function getParamSettings(param, key, keyParent, returnKey, subParts, noSelf) {
  var keyOrig = key;
  var object = param.object;
  if (!object) object = param.reference;
  var settings = $.extend(true, {}, parameter[object]);
  if (keyParent) settings = settings[keyParent];
  var value;
  var keys = [];

  var parts = [];
  if (param.detail) parts.push('Detail');
  if (param.objectSub) parts.push(ucfirst(param.objectSub));
  if (param.dashboard) parts.push('Dashboard');

  // add sub parts
  if (subParts && param.objectSub && settings.subs)
    $.each(settings.subs, function (i, sub) {
      if (
        sub &&
        sub != param.objectSub &&
        str_starts_with(param.objectSub, sub) &&
        param.objectSub.length - sub.length > 2
      )
        parts.push(ucfirst(sub));
    });

  if (role) parts.push(ucfirst(role));
  if (user.language && user.language != 'de' && keyParent == 'labels')
    parts.push(ucfirst(user.language));

  $.each(parts, function (i, part) {
    var k1 = key + part;
    keys.push(k1);
    $.each(keys, function (j, k2) {
      if (k2 != k1) keys.push(k2 + part);
    });
  });
  keys = keys.reverse();
  if (!noSelf) keys.push(key);

  $.each(keys, function (i, k) {
    if (isset(settings[k])) {
      value = settings[k];
      key = k;
      return false;
    }
  });

  if (
    isPlainObject(value) &&
    parameter[param.object][keyOrig] &&
    isPlainObject(parameter[param.object][keyOrig])
  )
    value = Object.assign({}, parameter[param.object][keyOrig], value);

  if (keyOrig == 'short' && value && str_starts_with(value, 'short'))
    key = value;

  if (param.returnKey || returnKey) return key;
  else return value;
}
